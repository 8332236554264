import "@/style/Edit.scss";
import comfirmBox from "@/components/ComfirmBox";
import EditPersonalPoint from "@/components/Input/EditPersonalPoint";


export default {
  data() {
    return {
      confirm: {
        show: false,
        title: "",
        context: "",
        okfunc: Function
      },
      editShow: false,
      modifyTemp: {},
      editPointTemp: {}
    }
  },

  computed: {
    personalPoint: {
      get() {
        return this.$store.state.schedule.personalList.point;
      }
    }
  },

  components: {
    comfirmBox,
    EditPersonalPoint,
  },

  mounted() {
    this.$store.dispatch("schedule/getPersonalPoint");
  },

  methods: {
    sendToCompany(data) {
      this.confirm.show = true;
      this.confirm.name = "推送確認";
      this.confirm.context = "請問要推送嗎？";
      this.confirm.okfunc = () => {
        this.$store.dispatch("schedule/sendToCompany", data);
      }
    },

    editPoint(data, point) {
      this.editShow = true;
      this.modifyTemp = _.cloneDeep(data);
      this.editPointTemp = _.cloneDeep(point);
    },

    updateModifyData(data) {
      this.$store.dispatch("schedule/savePersonalPoint", data).then((data) => {
        this.editShow = false;
      });
    },

    delectPoint(data) {
      let temp = _.cloneDeep(data);
      temp.method = "delete";

      this.confirm.show = true;
      this.confirm.name = "刪除確認";
      this.confirm.context = "請問要刪除本次編輯嗎？";
      this.confirm.okfunc = () => {
        this.$store.dispatch("schedule/savePersonalPoint", temp).then((result) => {
          let index = _.findIndex(this.personalPoint, (item, key) => {
            return item.uid == data.belong_ele;
          });
          
          if (index > -1) {
            let subIndex = _.findIndex(this.personalPoint[index].sub, (item, key) => {
              return item.uid == data.uid;
            });

            if (subIndex > -1) {
              this.personalPoint[index].sub.splice(subIndex, 1);
            }
          }
        });
      }
    },

    copyPoint(data) {
      let temp = _.cloneDeep(data);
      temp.method = "copy";

      this.confirm.show = true;
      this.confirm.name = "複製確認";
      this.confirm.context = "請問要複製本次編輯嗎？";
      this.confirm.okfunc = () => {
        this.$store.dispatch("schedule/savePersonalPoint", temp)
      }
    },
  },
}
