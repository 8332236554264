<template>
  <div class="w-100 position-relative">
    <EditPersonalPoint :show.sync="editShow" :pointTemp="modifyTemp" :mainInfor="editPointTemp"
      @updateData="updateModifyData" v-if="modifyTemp"></EditPersonalPoint>

    <div class="w-100 h-100 bg-white overflow-hidden shadow" style="max-width:26rem">
      <!-- {{personalPoint}} -->
      <div class="h-100 card rounded">
        <div class="card-header bg-dagee-blue p-1">
          <div class="d-flex align-items-center justify-content-between">
            <router-link :to="{name:'index'}" class="btn btn-sm px-2 text-dark">
              <font-awesome-icon :icon="['fas', 'chevron-left']" size='lg' />
            </router-link>
            <div class="flex-fill d-flex align-items-center text-dark">
              <b>我的編輯景點</b>
            </div>
          </div>
        </div>

        <div class="w-100 h-100 bg-light">
          <div class="p-2 overflow-auto" style="height:calc(100% - 37px)">

            <div class="w-100 d-flex align-items-center justify-content-between text-secondary mb-2"
              v-for="(point, index) in personalPoint" :key="point.uid">
              <div></div>
              <div class="flex-fill bg-white shadow-sm rounded px-2 pt-2 pb-0">
                <div class="w-100">
                  <div class="d-flex align-items-start justify-content-between">
                    <b style="min-width: 70px">景點名稱</b>
                    <span class="flex-fill">{{point.name}}</span>
                  </div>

                  <div class="d-flex align-items-start justify-content-between">
                    <b style="min-width: 70px">景點地址</b>
                    <span class="flex-fill">{{point.address}}</span>
                  </div>

                  <div class="d-flex align-items-start justify-content-between border-bottom pb-2">
                    <b style="min-width: 70px">編輯紀錄</b>
                    <span class="flex-fill">{{point.sub.length}}次</span>
                  </div>
                  <b-collapse class="pt-2" v-model="point.show">
                    <div v-for="(subPoint, index) in point.sub" :key="subPoint.uid"
                      class="d-flex align-items-start justify-content-between rounded p-2 mb-2 bg-light shadow-sm">
                      <div class="flex-fill">
                        <div class="d-flex align-items-start">
                          <b style="min-width: 70px">景點名稱</b>
                          <div>{{subPoint.name}}</div>
                        </div>
                        <div class="d-flex align-items-start">
                          <b style="min-width: 70px">介紹</b>
                          <div>{{subPoint.descriptionDetail}}</div>
                        </div>
                        <div class="d-flex align-items-start">
                          <b style="min-width: 70px">地址</b>
                          <div>{{subPoint.address}}</div>
                        </div>
                        <div class="d-flex align-items-start">
                          <b style="min-width: 70px">官網</b>
                          <div class="pms-content-1">{{subPoint.websiteUrl}}</div>
                        </div>
                        <div class="d-flex align-items-start">
                          <b style="min-width: 70px">電話</b>
                          <div>{{subPoint.phone}}</div>
                        </div>
                        <div class="d-flex align-items-start">
                          <b style="min-width: 70px">營業時間</b>
                          <div v-html="subPoint.openTime"></div>
                        </div>
                        <div class="d-flex align-items-start">
                          <b style="min-width: 70px">推送時間</b>
                          <div v-if="subPoint.transfer">{{subPoint.sendTime}}</div>
                          <div class="text-danger" v-else>尚未推送</div>
                        </div>
                      </div>
                      <div>
                        <div class="d-flex align-items-center justify-content-center" v-if="!subPoint.transfer">
                          <button class="btn btn-sm text-primary px-2 mb-2"
                            @click="sendToCompany(subPoint)">
                            <font-awesome-icon :icon="['fas', 'paper-plane']" size='lg' />
                          </button>
                        </div>

                        <div class="d-flex align-items-center justify-content-center" v-if="!subPoint.transfer">
                          <button class="btn btn-sm px-2 mb-2" @click="editPoint(subPoint, point)">
                            <font-awesome-icon :icon="['fas', 'pencil-alt']" size='lg' />
                          </button>
                        </div>

                        <div class="d-flex align-items-center justify-content-center">
                          <button class="btn btn-sm text-primary px-2 mb-2"
                            @click="copyPoint(subPoint)">
                            <font-awesome-icon :icon="['fas', 'copy']" size='lg' />
                          </button>
                        </div>

                        <div class="d-flex align-items-center justify-content-center" v-if="!subPoint.transfer">
                          <button class="btn btn-sm text-danger px-2 mb-2"
                            @click="delectPoint(subPoint)">
                            <font-awesome-icon :icon="['fas', 'trash-alt']" size='lg' />
                          </button>
                        </div>
                      </div>
                    </div>
                  </b-collapse>
                  <div class="d-flex align-items-center justify-content-center py-2"
                    @click="point.show = !point.show">
                    <font-awesome-icon :icon="['fas', 'ellipsis-h']" size='lg' />
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <comfirmBox :show.sync="confirm.show" :title="confirm.name" :context="confirm.context"
      :okfunc="confirm.okfunc">
    </comfirmBox>
  </div>
</template>

<script>
  import ctrl from "./PersonalPoint.js";
  export default ctrl;

</script>
