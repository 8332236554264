<template>
  <div class="w-100 position-absolute"
    style="width: 20rem; z-index: 1030; height:-webkit-fill-available" v-if="show">
    <div class="card rounded" style="max-width:26rem; height: inherit">
      <div class="d-flex align-items-center justify-content-center p-1 bg-dagee">
        <b class="flex-fill text-center pms-content-1">{{mainInfor.name}}</b>
        <!-- <div class="px-2">
          <font-awesome-icon :icon="['fas', 'times']" size='lg' />
        </div> -->
      </div>

      <div class="w-100 h-100 overflow-auto">
        <b-card :body-class="['p-0']">
          <div class="w-100 h-100">
            <b-list-group class="text-secondary border-top" flush>
              <b-list-group-item>
                <div class="d-flex align-items-start">
                  <div style="width: 30px;">
                    <font-awesome-icon :icon="['fas', 'signature']" size='lg' />
                  </div>
                  <div class="flex-fill d-flex flex-column pl-2">
                    <b>景點名稱</b>
                    <b-form-input v-model="showPointEditData.name"></b-form-input>
                  </div>
                </div>
              </b-list-group-item>

              <b-list-group-item>
                <div class="d-flex align-items-start">
                  <div style="width: 30px;">
                    <font-awesome-icon :icon="['fas', 'pencil-alt']" size='lg' />
                  </div>
                  <div class="flex-fill d-flex flex-column pl-2">
                    <b>介紹</b>
                    <b-form-textarea rows="6" max-rows="6"
                      v-model="showPointEditData.descriptionDetail">
                    </b-form-textarea>
                  </div>
                </div>
              </b-list-group-item>

              <b-list-group-item>
                <div class="d-flex align-items-start">
                  <div style="width: 30px;">
                    <font-awesome-icon :icon="['fas', 'map-marker-alt']" size='lg' />
                  </div>
                  <div class="flex-fill d-flex flex-column pl-2">
                    <b>地址</b>
                    <b-form-input v-model="showPointEditData.address"></b-form-input>
                  </div>
                </div>
              </b-list-group-item>

              <b-list-group-item>
                <div class="d-flex align-items-start">
                  <div style="width: 30px;">
                    <font-awesome-icon :icon="['fas', 'globe-asia']" size='lg' />
                  </div>
                  <div class="flex-fill d-flex flex-column pl-2">
                    <b>官網</b>
                    <b-form-input v-model="showPointEditData.websiteUrl"></b-form-input>
                  </div>
                </div>
              </b-list-group-item>

              <b-list-group-item>
                <div class="d-flex align-items-start">
                  <div style="width: 30px;">
                    <font-awesome-icon :icon="['fas', 'phone']" size='lg' />
                  </div>
                  <div class="flex-fill d-flex flex-column pl-2">
                    <b>電話</b>
                    <b-form-input v-model="showPointEditData.phone"></b-form-input>
                  </div>
                </div>
              </b-list-group-item>

              <b-list-group-item>
                <div class="d-flex align-items-start">
                  <div style="width: 30px;">
                    <font-awesome-icon :icon="['fas', 'clock']" size='lg' />
                  </div>
                  <div class="flex-fill d-flex flex-column pl-2">
                    <b>營業時間</b>
                    <b-form-textarea rows="7" max-rows="7" v-model="showPointEditData.openTime">
                    </b-form-textarea>
                  </div>
                </div>
              </b-list-group-item>

              <div class="d-flex align-items-center justify-content-around py-2">
                <button class="btn btn-sm btn-outline-success" @click="saveShowPoint()">儲存</button>
                <button class="btn btn-sm btn-outline-danger" @click="cancelShowPoint()">取消</button>
              </div>
            </b-list-group>
          </div>

        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        path: process.env.VUE_APP_DATA_PATH,
      }
    },

    computed: {
      showEdit: {
        get() {
          return this.$props.show;
        },

        set(data) {
          this.$emit("update:show", data);
        }
      },

      showPointEditData: {
        get() {
          return this.$props.pointTemp;
        }
      }
    },

    props: {
      show: {
        type: Boolean
      },

      mainInfor: {
        type: Object,
        default: {}
      },

      pointTemp: {
        type: Object
      },
    },

    methods: {
      getRealyUrl() {
        return (path) => {
          if (path.indexOf("data/") > -1) {
            return this.path + path;
          } else {
            return path;
          }
        }
      },

      saveShowPoint() {
        this.$emit("updateData", this.showPointEditData);
      },

      cancelShowPoint() {
        this.$emit("update:show", false);
      }
    }
  }

</script>
